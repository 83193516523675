<template>
  <div id="vistorMsgApp">
    <van-form @submit="onSubmit">


      <van-cell-group title="人员信息" inset>
        <van-field
            v-model="id"
            name="id"
            v-show="false"
        ></van-field>
        <van-field
            v-model="name"
            name="name"
            label="姓名"
            placeholder="姓名"
            required
            :rules="[{ required: true, message: '请填写姓名' }]"
        ></van-field>
        <Picker
            name="genderDicID"
            label="性别"
            displayField="name"
            valueField="id"
            :required=true
            select-first
            v-model:value="genderDicID"
            :defConfig="{
                  portName:'touch.system.Dic.getChildDicsByCode',
                  data: {'code': 'BASE_BUSINESS_GENDER'},
                }"
            :rules="[{ required: true, message: '请选择性别' }]"
        />
        <van-field
            v-model="phone"
            name="phone"
            :required=true
            label="手机号码"
            placeholder="手机号码"
            :rules="[{ required: true, message: '请填写手机号码' }]"
        ></van-field>
        <van-field
            v-model="unit"
            name="unit"
            label="所属公司"
            placeholder="所属公司"
            readonly
        ></van-field>
        <van-field
            v-model="unitPrice"
            name="unitPrice"
            label="每公里单价"
            placeholder="每公里单价"

        ></van-field>
        <van-field
            v-model="emergencyContact"
            name="emergencyContact"
            label="紧急联系人"
            placeholder="紧急联系人"
        ></van-field>
        <van-field
            v-model="emergencyContactPhone"
            name="emergencyContactPhone"
            label="紧急联系方式"
            placeholder="紧急联系方式"
        ></van-field>
        <van-field
            v-model="idCard"
            name="idCard"
            :required=true
            label="身份证号"
            placeholder="身份证号"
            :rules="[{ required: true, message: '请填写身份证号码' },regexPack.idCard()]"
        ></van-field>
        <van-field name="faceIDAttach" label="头像">
          <template #input>
            <van-uploader name="faceIDAttach" v-model="faceIDAttach"
                          :before-read="beforeRead"
                          :after-read="afterRead"
                          max-count="1"></van-uploader>
          </template>
        </van-field>

      </van-cell-group>
      <div style="margin: 4.26667vw;">
        <van-button round block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {ref} from "vue";
import {Dialog} from "vant";
import _ from "lodash";
import Picker from "../../components/Picker";
import {regexPack} from "@/utils";

export default {
  name: "VisitorInfo",
  computed: {
    regexPack() {
      return regexPack
    }
  },
  components: {Picker},
  data() {
    return {
      id: '',
      userID: '',
      name: '',
      phone: '',
      unit: '',
      genderDicID: '',
      idCard: '',
      unitPrice:'',
      emergencyContactPhone:'',
      emergencyContact:'',
      faceIDAttach: [],
      genderDicList: []
    };
  },
  methods: {
    getVisitor: function () {
      var me = this;
      var option = {
        portName: 'tms.TmsDriver.vapp.getNowDriver',
        data: {},
        needLoading: false,
        successCallback: function (data) {
          var entities = data.entities;
          if (_.isEmpty(entities)) {
            return;
          }
          var entity = entities[0];
          me.id = entity['id'];
          me.userID = entity['user.id'];
          me.name = entity['name'];
          me.phone = entity['phone'];
          me.unit = entity['belongCompany.cnName'];
          me.genderDicID = entity['genderDic.id'];
          me.idCard = (entity['idCard'])
          me.emergencyContact = (entity['emergencyContact'])
          me.emergencyContactPhone = (entity['emergencyContactPhone'])
          me.unitPrice = (entity['unitPrice'])
          me.faceIDAttach = _.isEmpty(entity['faceIDAttach']) ? [] : entity['faceIDAttach'];
        },
        errorCallback: function (data) {

        }
      };
      this.$sapi.callPort(option);
    },
    getDicColumns: function (comboColumns, dicCode, firstItem) {
      var me = this;
      var option = {
        portName: 'touch.system.Dic.getChildDicsByCode',
        data: {code: dicCode},
        needLoading: true,
        successCallback: function (data) {
          var entities = data.entities;
          for (var index in entities) {
            var item = entities[index];
            if (!(firstItem === null || firstItem === undefined) && index === "0") {
              me[firstItem] = ref(item.id);
            }
            me[comboColumns].push({
              text: item.name,
              value: item.id
            });
          }
        }
      };
      this.$sapi.callPort(option);
    },
    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 75, true);
    },
    afterRead(file) {
      this.$sapi.setUploadFileModel(file);
    },
    onSubmit: function (values) {
      var me = this;
      // values['user.id'] = me.userID;
      // values['id'] = me.id;
      // values['faceIDAttach'] = me.faceIDAttach;
      var option = {
        portName: 'tms.TmsDriver.saveTmsDriverVapp',
        data: values,
        needLoading: false,
        successCallback: function (data) {
          me.id = data.data.id;
          me.$dialog.alert({
            title: '',
            message: '保存成功',
          }).then(() => {
            me.$router.go(-1);
          });
        }
      };
      this.$sapi.callPort(option);
    },
  },
  created: function () {
    var me = this;
    me.getVisitor();
  }
}
</script>

<style scoped>

</style>